@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,700,900);
@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Arvo:400,400i,700,700i);
html,
body {
	color: #898989;
	font-size: 15px;
	font-family: 'Raleway', sans-serif;
	line-height: normal;
	font-weight: 400;
	overflow-x: hidden;
}

body {
	overflow-x: hidden;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	letter-spacing: 0;
	font-weight: normal;
	position: relative;
	padding: 0;
	font-weight: normal;
	line-height: normal;
	color: #1f1f1f;
	margin: 0
}

h1 {
	font-size: 24px
}
h6 {
	font-size: 14px;
	margin-bottom: 10px;
}
h2 {
	font-size: 30px;
	color: #000;
	line-height: 36px;
	font-weight: 600;
	position: relative;
	margin-bottom: 15px;
	text-transform: uppercase;
	margin-top: 15px;
}

h3 {
	font-size: 24px;
	color: #000;
	line-height: normal;
	font-weight: 500;
	text-transform: none;
	margin-bottom: 20px;
	font-weight: 600;
}

h4 {
	font-size: 18px;
	color: #000;
	line-height: 21px;
	font-weight: 600;
	text-transform: none;
	margin: 30px 0 20px 0;
}

h5 {
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	line-height: normal;
	color: #000;
}

p {
	color: #707070;
	font-size: 14px;
	font-size: 1rem; 
	line-height: 21px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
