.about_blog {
	background: #f5f5f5;
    margin: 0 5%;
}

.about_cont_blog {
	padding: 50px;
}

.about_cont_blog p {
	margin: 0;
	line-height: 24px;
}

.about_cont_blog ul {
	margin-top: 15px;
}

.about_cont_blog ul li {
	float: left;
	width: 100%;
	margin: 5px 0;
	font-size: 15px;
	color: #333;
	font-weight: 500;
}

.about_cont_blog ul li i {
	margin-right: 10px;
}