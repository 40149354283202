.tsupLink{
    color: green;
    padding: 10px 10px;
    border-radius: 50px;
    position: fixed;
    bottom: 5%;
    right: 2.5%;
    z-index: 5;
    cursor: pointer;
    font-size: 2rem;
}
.tsupLinkPopup{
    width: 30%;
    background: white;
    border-radius: 10px;
    position: fixed;
    bottom: 15%;
    z-index: 5;
    right: 2.5%;
}
.tsupLinkPopup div:first-child{
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
    justify-content: center;
    align-items: center;
    background: green;
    border-radius: 10px 10px 0 0;
    color: white;
    position: relative;
}
.tsupLinkPopup div:first-child p{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20%;
    font-size: 0.875rem;
}
.tsupLinkPopup .detailed{
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
    background: whitesmoke;
    color: black;
    padding: 5px;
    margin: 10px 0;
    font-size: 0.875rem;
}
.tsupLinkPopup img{
    width: 120%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.tsupLinkPopup a:hover{
    text-decoration: none;
}
.tsupLinkPopup p:last-child{
    text-align: center;
}
.tsupLinkPopup h2{
    font-size: 0.9rem;
    font-weight: 700;
}
.tsupLinkPopup i{
    font-size: 50px;
    padding: 10px 0 10px 20px;
}
@media only screen and (max-width: 998px){
    .tsupLinkPopup{
        width: 95%;
    }
    .tsupLink{
        color: green;
        padding: 5px 10px;
        border-radius: 50px;
        position: fixed;
        bottom: 2%;
        right: 2.5%;
        z-index: 5;
        cursor: pointer;
        font-size: 2rem;
        background-color: white;
    }
}