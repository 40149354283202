.sliderSpell{
    height: 50vh;
    width: 50%;
    margin: auto;
    margin-top: 2.5%;
    margin-bottom: 7%;
    position: relative;
}

.sliderSpell .innerSpell{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.sliderSpell .contentSpell{
    position: absolute;
    left: -8%;
    top: 15%;
    width: 85%;
    padding: 20px;
    border-radius: 10px;
    bottom: 15%;
    background-color: whitesmoke;
}

.sliderSpell .contentSpell h1{
    font-family: "Arizonia", cursive;
    margin-bottom: 20px;
    /*color: #5c5c5c;*/
    font-weight: 900;
    font-size: 1.5rem;
}

.slider .content p{
    color: #5c5c5c;
    font-weight: 500;
    font-size: 1.2rem;
}

.sliderSpell .slideBtns{
    position: absolute;
    top: 105%;
    z-index: 1;
    color: white;
    padding: 5px;
    background-color: black;
    border: none;
    border-radius: 0;
}

.btns1{
    left: 46.5%;
}
.btns2{
    right: 46.5%;
}
@media only screen and (max-width: 998px){
    .sliderSpell{
        height: 77vh;
        width: 100%;
    }
    .sliderSpell .contentSpell{
        position: absolute;
        left: 5%;
        right: 5%;
        bottom: 10%;
        padding: 20px;
        border-radius: 20px 0;
        overflow: auto;
        background-color: whitesmoke;
    }
    .sliderSpell .contentSpell h1{
        margin-bottom: 10px;
        /*color: #5c5c5c;*/
        font-weight: 700;
        font-size: 2.0rem;
    }
    .sliderSpell .contentSpell p{
        font-size: 1rem;
    }
    .sliderSpell .slideBtns{
        position: absolute;
        top: 102%;
        z-index: 1;
        color: white;
        padding: 5px;
        background-color: black;
        border: none;
        border-radius: 0;
    }
    .btns1{
        left: 43.5%;
    }
    .btns2{
        right: 43.5%;
    }
}