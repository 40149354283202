.footerForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    margin: -5% auto 0 auto;
}

.bkFooter {
    background: url("../../public/images/istockphoto-2156708063-612x612.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 8% 0;
    margin-top: 5%;
}

.bkFooter h4 {
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    font-size: 1.5rem;
    font-weight: 900;
    text-shadow: 0.5px 0.5px white;

}

.bkFooter h2 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
    font-weight: 900;
}

.footerForm .leftSide {
    background-color: rgb(53, 51, 51);
    text-align: center;
    padding: 10% 20%;
    color: white;
}

.footerForm .leftSide h1 {
    padding: 20px 0;
    font-weight: 900;
}

.footerForm .leftSide h3 {
    font-weight: 500;
}

.footerForm form {
    padding: 10% 5%;
    background-color: white;
}

.footerForm form div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.footerForm form input,
.footerForm form textarea,
.footerForm form select {
    padding: 8px;
    border: 1px solid grey;
    margin-bottom: 10px;
    width: 100%;
}

.footerForm form input:focus,
.footerForm form textarea:focus,
.footerForm form select:focus {
    outline: none;
}

@media only screen and (max-width: 998px) {
    .footerForm {
        grid-template-columns: repeat(1, 1fr);
        width: 90%;
        margin: -8% auto 0 auto;
    }

    .bkFooter h4 {
        font-size: 1.2rem;
    }

    .bkFooter h2 {
        font-size: 1.4rem;
        width: 80%;
        margin: auto;
    }

    .footerForm form div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
    }

    .footerForm form {
        padding: 10% 0%;
        background-color: white;
    }

    .footerForm form input,
    .footerForm form textarea,
    .footerForm form select {
        margin-bottom: 10px;
    }
}