.slider{
    height: 70vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.slider .inners{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.slider .content{
    position: absolute;
    left: 40%;
    right: 10%;
    bottom: 10%;
    padding: 20px;
    border-radius: 20px 0;
    background-color: whitesmoke;
}

.slider .content h1{
    font-family: "Arizonia", cursive;
    margin-bottom: 20px;
    /*color: #5c5c5c;*/
    font-weight: 900;
    font-size: 3.5rem;
}

.slider .content p{
    color: #5c5c5c;
    font-weight: 500;
    font-size: 1.2rem;
}

.slider .slideBtns{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: white;
    padding: 10px;
    background-color: rgb(31, 31, 31);
    border: none;
    border-radius: 5px;
}

.btn1{
    position: absolute;
    left: 2.5%;
}
.btn2{
    right: 2.5%;
}
@media only screen and (max-width: 998px){
    .slider .content{
        position: absolute;
        left: 5%;
        right: 5%;
        bottom: 10%;
        padding: 20px;
        border-radius: 20px 0;
        background-color: whitesmoke;
    }
    .slider .content h1{
        margin-bottom: 10px;
        /*color: #5c5c5c;*/
        font-weight: 700;
        font-size: 2.0rem;
    }
    .slider .content p{
        font-size: 1rem;
    }
}