.adress_cont{
    padding: 0 5%;
}
.adress_cont p {
	color: #737373;
}

.adress_cont h4 {
	margin: 0 0 20px 0;
    color: #333;
}

.adress_cont>h4+p {
	margin: 0 0 30px 0;
	font-weight: 500;
	color: #333;
}
.adress_cont i {
	color: #039ee3;
    font-size: 1.8rem;
}

@media only screen and (max-width: 998px){
	.adress_cont h4 {
		font-size: 1rem;
	}
	.adress_cont i {
		color: #039ee3;
		font-size: 1.4rem;
	}
	
}