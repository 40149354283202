.main_heading {
    padding-top: 25px;
	position: relative;
	margin-bottom: 65px;
}

.main_heading::after {
	content: "";
	display: block;
	margin: 20px auto 0;
	width: 80px;
	height: 5px;
	background: red;
} 
.body h2,
.spels-charms h2{
    font-size: 1.5rem;
    padding-top: 20px;
    padding-bottom: 10px;
    color: rgb(53, 53, 53);
}
.body p{
    font-size: 1rem; /* 20px in most browsers */
    font-weight: 300; /* Adjust font weight as needed */
    line-height: 1.6; /* Adjust line height for better readability */
    text-align: justify;
}
.body{
    background: linear-gradient(to bottom, transparent, whitesmoke);
    padding: 20px 10% 10px 10%;
}
.body .dividedTwo{
    display: flex;
    padding: 10px 10%;
    height: 85vh;
}
.body .dividedTwo div:first-child{
    width: 40%;
    background: url("../../public/images/witch2.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

}
.body .dividedTwo div:last-child{
    width: 60%;
    background-color: white;
    padding: 30px 20px;
    align-self: center;
    margin-left: -40px;
    border-radius: 5px;
}
.body .main_heading{
    width: 50%;
    margin: auto;
}
.main_heading h2{
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 1.6rem;
    text-transform: capitalize;
}
.charms{
    padding: 2.5% 5%;
    color: whitesmoke;
    background-color: rgb(62, 92, 120);
}
.charms h2{
    font-family: "Arizonia", cursive;
    font-size: 2.8rem;
    font-weight: 900;
    padding-top: 10px;
    text-align: center;
    text-transform: capitalize;
    text-shadow: 1px 1px tomato;
}
.charms h3{
    font-size: 1.5rem;
    font-weight: 900;
    width: 100%;
    padding-top: 10px;
}
.charms h4{
    text-shadow: 2px 2px green;
    padding-top: 20px;
}
.charms p{
    font-size: 1.1rem;
    color: whitesmoke;
}
.charms img{
    border-radius: 10px;
    transition: .4s;
}
.charms img:hover{
    /*animation: shake 2s ease-in-out infinite;*/
    transform: scale(0.95);
    cursor: pointer;
}
.spels-charms{
    padding: 0 5%;
}
.spels-charms h3{
    font-size: 1.5rem;
    color: rgb(57, 57, 57);
    font-weight: 900;
    width: 100%;
}
.spels-charms p{
    font-weight: 300; /* Adjust font weight as needed */
    line-height: 1.6; /* Adjust line height for better readability */
    text-align: justify;
}
.charmed div{
    width: 30%;
    border: 1px solid rgb(209, 207, 207);
    padding: 20px;
    border-radius: 5px;
    background: rgba(246, 216, 47, 0.2);
}
/*
.charmed div:nth-child(1){
    background: url("../../public/images/witch1.webp");
    background-position: right;
}
.charmed div:nth-child(2){
    background: url("../../public/images/witch1.webp");
    background-position: top;
}
.charmed div:nth-child(3){
    background: url("../../public/images/witch1.webp");
    background-position: bottom;
}
.charmed div:nth-child(4){
    background: url("../../public/images/witch1.webp");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
*/
.charmed2 div{
    width: 32%;
    border: 1px solid rgb(209, 207, 207);
    padding: 20px;
    border-radius: 5px;
    background: darkcyan;
    color: white;
    border-bottom: 3px solid red;
}
.charmed2 h3{
    color: white;
    font-size: 1.5rem;
}
.charmed a{
    margin-top: 10px;
}
.charmed a,
.charmed2 a{
    padding: 8px 30px;
}
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-1px) rotate(-1deg); }
    50% { transform: translateX(20px) rotate(1deg); }
    75% { transform: translateX(-1px) rotate(-1deg); }
    100% { transform: translateX(0); }
}
/* Apply the animation to the image */
.shake-image {
    animation: shake 1s ease-in-out infinite; /* Adjust animation duration and timing as needed */
}
.wd-75{
    width: 75%;
}
@media only screen and (max-width: 998px){
    .charmed2 div,
    .charmed div{
        width: 100%;
    }
    .wd-75{
        width: 100%;
    }
    .body .dividedTwo{
        display: block;
        padding: 10px 0;
        height: auto;
        position: relative;
    }
    .body .dividedTwo div:first-child{
        width: 100%;
        background: url("../../public/images/witch2.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

    
    }
    .body .dividedTwo div:last-child {
        width: 100%;
        padding: 30px 20px;
        align-self: center;
        margin-left: 0;
        border-radius: 5px;
        position: relative;
        z-index: 0;
        overflow: hidden; /* Ensure the blur effect doesn't overflow */
    }
    
    .body .dividedTwo div:last-child::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: url("../../public/images/witch2.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        filter: blur(5px); /* Apply blur to the background image */
        opacity: 0.8; /* Make the background image faint */
    }
    
    .body .dividedTwo div:last-child::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: linear-gradient(to bottom, black, transparent, black);
    }
    .body .dividedTwo div:last-child p{
        color: white;
    }
    .body .main_heading{
        width: 100%;
        margin: auto;
    }
    .main_heading h2{
        font-size: 1.2rem;
    }
    .charmed2 h3,
    .spels-charms h3{
        font-size: 1.2rem;
    }
    .charmed div:nth-child(1),
    .charmed div:nth-child(2),
    .charmed div:nth-child(3),
    .charmed div:nth-child(4){
        background-position: bottom;
        background-repeat: unset;
        background-size: unset;
    }
    p {
        color: #111010;
    }
}