.nav-menu{
    position: relative;
    padding: 0 5%;
    background: whitesmoke;
}
.nav-menu nav a{
    color: gray;
    font-size: 1rem;
}
.nav-menu nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}
.nav-menu div a{
    padding: 10px;
}
.nav-menu a {
    margin-right: 20px;
    padding: 25px 8px;
    font-size: 0.875rem;
    font-weight: bolder;
    cursor: pointer;
    position: relative;
}
.nav-menu a div{
    display: none;
    position: absolute;
    border-top: 2px solid white;
    background-color: rgba(13, 13, 13, 0.6);
    top: 100%;
    min-width: 300px;
    z-index: 1;
}
.nav-menu a div a{
    display: block;
    margin: 0;
    padding: 20px;
    color: white;
}
.nav-menu a div a:hover{
    background-color: darkcyan;
}
.nav-menu a:hover > div{
    display: block;
}
.menu{
    display: none;
}
@media only screen and (max-width: 998px){
    .nav-menu{
        padding: 0 0;
    }
    .menu{
        display: block;
        color: gray;
    }
    .nav-menu nav {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 3;
        background: rgb(49, 47, 47);
        display: none;
        width: 70%;
    }
    .nav-menu nav a {
        display: block;
        width: 100%;
    }
}