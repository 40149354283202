.banner{
    background: url('../../public/images/ad2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 230px;
    background-position: center center;
    padding: 0 5%;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.page-title {
	color: #fff;
	font-size: 30px;
	font-weight: 700;
	line-height: 45px;
	margin-bottom: 15px;
}

.breadcrumb {
	background: transparent;
	padding: 0;
	margin: 0;
	color: #fff;
}

.breadcrumb li {
	font-weight: 500;
	font-size: 15px;
}